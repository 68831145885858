import GuardsButtons from "@components/atoms/GuardsButtons";
import Guard from "@components/atoms/Guard";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/autoplay';
import 'swiper/css/free-mode';
import { useState } from "react";
import Image from "next/image";
import { FreeMode, Autoplay } from 'swiper/modules';
import { useIsMobile } from "@hooks/useIsMobile";
import Link from "next/link";

export default function FriendsSwiper () {
  const [stageSliderInstance, setStageSliderInstance] = useState(null);
  const isMobile = useIsMobile()
  const friends = [
    {
      width: 207,
      height: 70,
      logo: 'webitfactory.webp',
      link: 'https://webitfactory.io/'
    },
    {
      width: 206,
      height: 47,
      logo: 'hodlezz.webp',
      link: 'https://hodlezz.com/'
    },
    {
      width: 207,
      height: 70,
      logo: 'webitlabs.webp',
      link: 'https://webitlabs.io/'
    },
    {
      width: 191,
      height: 90,
      logo: 'ovidiu.webp',
      link: ''
    },
    {
      width: 271,
      height: 51,
      logo: 'webstetica.webp',
      link: 'https://www.behance.net/webstetica'
    },
    {
      width: 320,
      height: 62,
      logo: 'tradesilvania.webp',
      link: 'https://tradesilvania.com/ro/'
    },
    {
      width: 196,
      height: 116,
      logo: 'complice.webp',
      link: 'http://www.complice.ro/'
    },
    {
      width: 257,
      height: 70,
      logo: 'bitmarket.webp',
      link: 'https://bitmarket.ro/'
    },
    {
      width: 206,
      height: 67,
      logo: 'travel.webp',
      link: ''
    },
    {
      width: 196,
      height: 116,
      logo: 's4f.webp',
      link: 'https://sense4fit.com/'
    },
  ]

  return (
    <>
     <Swiper
        slidesPerView={'auto'}
        grabCursor={true}
        spaceBetween={20}
        initialSlide={0}
        loop={true}
        speed={3000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        modules={[FreeMode, Autoplay]}
        onInit={(ev: any) => {
          setStageSliderInstance(ev);
        }}
        style={{
          display: 'flex',
          height: '100px',
          marginTop: '78px'
        }}
        className='roadmap-swiper'
      >
        {friends.map((friend: {width: number, height: number, logo: string, link: string}, index: number) => {
          return (
            <SwiperSlide key={index} style={{ width: 'fit-content' }}>
              <div className='flex items-center mx-10 w-fit h-full'>
                <Link href={friend.link} target='_blank' title={friend.link}>
                  <Image loading='lazy' src={`/friends/${friend.logo}`} height={isMobile ? 50 : friend.height} width={friend.width} alt='partner' />
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  )
}
